.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Couche de fond noire semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* S'assurer qu'il est au-dessus des autres éléments */
}

.popup-inner {
    background-color: white;
    border-radius: 15px;
    /* Bords arrondis */
    padding: 20px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    /* Ombre pour un effet élégant */
}

.popup-inner h1 {
    margin: 0 0 20px;
    font-size: 24px;
    color: #333;
}

.popup-inner p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.popup-inner button {
    position: absolute;
    top: 15px; /* Ajustement pour éviter qu'elle soit collée au bord */
    right: 15px; /* Placement précis en haut à droite */
    background: none;
    border: none;
    font-size: 18px; /* Taille appropriée */
    color: #aaa;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    padding: 5px; /* Réduction de la zone cliquable */
    width: auto; /* Empêche le bouton de prendre toute la largeur */
    height: auto; /* Empêche une hauteur excessive */
    transition: 0.5s;
}


.popup-inner a {
    display: inline-block;
    text-decoration: none;
    background-color: #007bff;
    /* Couleur du bouton */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.2s ease-in-out;
}

.popup-inner a:hover {
    background-color: #0056b3;
}