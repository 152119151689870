@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(-500px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.titlePageHome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 300px;
}

.navhead {
    width: 100%;
    z-index: 1000;
}


.background-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.background-video {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.text h1 {
    color: white;
    font-size: 80px;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    flex-direction: column;
    text-align: center;
}

.content_acceuil {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.appear {
    width: 100%;
    height: 10vh;
    position: absolute;
    bottom: 120px;
    opacity: 0;
    transition: 0.5s;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.appear_content {
    width: 80%;
    height: 10vh;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.appear .boite {
    width: 300px;
    min-width: 300px;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.504);
    padding: 20px;
    border-top: 3px solid #ff3f00;
}

.appear .boite h1 {
    height: 50px;
    color: #220c3c;
    font-size: 30px;
}

.appear .boite a {
    color: #ff3f00;
    font-weight: bold;

}

.appear:hover {
    opacity: 1;
}

.content_acceuil .content_left {
    width: 50%;
}

.content_acceuil .content_right {
    height: 100vh;
    width: 80%;
    text-align: center;
}

.content_acceuil .content_right h1 {
    width: 100%;
    line-height: 150px;
    margin: 0 auto;
    font-size: 120px;
}

.content_acceuil img {
    object-fit: cover;
    height: 500px;
    border-radius: 15px;
}

.carousel-root {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.content_acceuil .carroussel {
    width: 80%;
    height: 70vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_acceuil .carroussel a {
    font-size: 30px;
    color: white;
    font-weight: 500;
    position: relative;
    top: -80px;

    background-color: rgba(0, 0, 0, 0.514);

    padding: 10px 20px;

    border-radius: 15px;
}

.who {
    height: 350px;
    width: 100%;
    background-color: #f5f5f5;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0 67%);
    display: flex;
}

.whoo {
    width: 80%;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;
}

.who_content {
    width: 50%;
    margin: 0 auto;
    padding-top: 30px;
}

.who_content h1 {
    text-align: left;
    font-size: 30px;
    color: #220c3c;
    font-weight: 700;
    margin: 0;
}

.who_content p {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    margin: 12px 0;
    width: 100%;
}

.who_right {
    width: 40%;
}

.who_right img {
    transform: scale(1);
    height: 200px;
    margin-top: 20px;

    border-radius: 50px;
}

.carrousel {
    background-color: white;
    position: relative;
    z-index: 100;
    margin: 0 auto;
    width: 90%;
    max-width: 1530px;
    padding-bottom: 80px;
}

.carrousel-item {
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
}

.carrousel ul {
    list-style: none;

    display: flex;
    text-align: center;
    justify-content: center;
}

.carrousel .activité {
    text-align: left;
    margin-top: 0;
}

.activitéP {
    text-align: left;
    margin: 0;
}

.up_case {
    font-size: 50px;
    color: #220c3c;
}

.carrousel h2{
    font-size: 30px;
    margin-top: 20px;
    color: #220c3c;
    font-weight: 700;
}

.carrousel-item {
    position: relative;
}

.boite {
    cursor: pointer;
}

.case {
    padding: 30px;
    width: 300px;
    margin: 0 auto;

    height: 400px;
    border: 1px solid rgb(233, 233, 233);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: 0.2s;
    cursor: pointer;
    border-top: 3px solid #ff3f00;
    -webkit-box-shadow: 2px 2px 23px 1px rgba(0, 0, 0, 0.09);
    box-shadow: 2px 2px 23px 1px rgba(0, 0, 0, 0.09);
}

.carrousel-item img {
    width: 300px;
    height: 200px;
    object-fit: cover;
}

.down_case {
    margin-top: auto;
    /* Ajout de cette propriété pour pousser l'élément vers le bas */
}

.case p {
    width: 70%;
    text-align: center;
    margin: 0 auto;
}

.case:hover {
    border-top: 3px solid #ff3f00;
    -webkit-box-shadow: 2px 2px 23px 1px rgba(0, 0, 0, 0.09);
    box-shadow: 2px 2px 23px 1px rgba(0, 0, 0, 0.09);
}

.middle_case {
    margin-top: 20px;
}

.case a {
    color: #ff3f00;
    font-weight: bold;
}

.styles-module_carousel-arrow__26sRw {
    display: none;
}

.clipath_carou {
    height: 200px;
    width: 100%;
    background-color: #f5f5f5;
    
    -webkit-clip-path: polygon(0 25%, 100% 10%, 100% 100%, 0 100%);
    clip-path: polygon(0 25%, 100% 10%, 100% 100%, 0 100%);

}

.text p {
    color: white;
    font-size: 40px;
    margin-top: 20px;
    font-weight: 800;
    position: absolute;
    z-index: -1;
}

.h2o {
    opacity: 0;
    top: 1000px;
    left: 15%;
    animation: breath 5s ease-in-out infinite;
    animation-delay: 0s;
}

.NH3 {
    opacity: 0;
    top: 1000px;
    left: 10%;
    animation: breath 5s ease-in-out infinite;
    animation-delay: 4s;
}

.O2 {
    opacity: 0;
    top: 1050px;
    left: 10%;
    animation: breath 5s ease-in-out infinite;
    animation-delay: 2s;
}

.CO2 {
    opacity: 0;
    top: 1050px;
    left: 35%;
    animation: breath 5s ease-in-out infinite;
    animation-delay: 1s;
}

.N2 {
    opacity: 0;
    top: 1020px;
    left: 55%;
    animation: breath 6s ease-in-out infinite;
    animation-delay: 7s;
}

.CH4 {
    opacity: 0;
    top: 1020px;
    left: 75%;
    animation: breath 5s ease-in-out infinite;
    animation-delay: 3.5s;
}

.commentaire {
    clip-path: polygon(0 11%, 100% 25%, 100% 100%, 0 100%);
    height: 600px;
    width: 100%;
    background-color: #f5f5f5;
}

.img img {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.719);
}

.img h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-style: italic;
    color: white;

    margin-top: 5px;
    font-size: 25px;
}

.img .img1 {
    position: absolute;
    top: 160px;
    left: 10%; 
}

.img .img1 img {
    opacity: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;

    animation: breath2 5s ease-in-out infinite;
    animation-delay: 1.3s;
}

.img .img1 h1 {
    animation: breath 5s ease-in-out infinite;
    animation-delay: 1.3s;
}

.img .img2 {
    position: absolute;
    top: 200px;
    left: 84%; 
}

.img .img2 img {
    opacity: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;

    animation: breath2 5s ease-in-out infinite;
    animation-delay: 0.7s;
}

.img .img2 h1 {
    animation: breath 5s ease-in-out infinite;
    animation-delay: 0.7s;
}


.img .img3 {
    position: absolute;
    top: 500px;
    left: 70%; 
}

.img .img3 img {
    opacity: 0;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;

    animation: breath2 5s ease-in-out infinite;
    animation-delay: 0.1s;
}

.img .img3 h1 {
    animation: breath 5s ease-in-out infinite;
    animation-delay: 0.1s;
}

.img .img4 {
    position: absolute;
    top: 530px;
    left: 15%; 
}

.img .img4 img {
    opacity: 0;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;

    animation: breath2 5s ease-out infinite;
    animation-delay: 1s;
}

.img .img4 h1 {
    animation: breath 5s ease-in-out infinite;
    animation-delay: 1s;
}

.img .img5 {
    position: absolute;
    top: 110px;
    left: 45%; 
}

.img .img5 img {
    opacity: 0;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;

    animation: breath2 5s ease-out infinite;
    animation-delay: 0.7s;
}

.img .img5 h1 {
    animation: breath 5s ease-in-out infinite;
    animation-delay: 0.7s;
}


@keyframes breath {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        top: -5px;
        top: 0px;
    }
}


@keyframes breath2 {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* @media (max-width: 2300px) {
    .carrousel-item {
        grid-template-columns: repeat(3, 1fr);
    }

    .case {
        width: 300px;
        margin-top: 20px;
    }
} */

/* @media (max-width: 1700px) {
    .carrousel-item {
        grid-template-columns: repeat(2, 1fr);
    }
} */


@media (max-width: 1670px) {
    .carrousel-item {
        width: 80%;
        grid-template-columns: repeat(3, 1fr);
    }

    .carrousel h2{
        font-size: 25px;
    }

    .case p{
        font-size: 15px;
    }

    .case {
        width: 350px;
    }

    .down_case {
        width: 80%;
        margin: 0 auto;
        font-size: 15px;
    }
}


@media (max-width: 1530px) {
    .who {
        clip-path: none;
        margin-bottom: 40px;
        height: auto;
        padding-bottom: 30px;
    }
}

@media (max-width: 1200px) {
    .appear {
        display: none;
    }
}

@media (max-width: 960px) {
    .carrousel-item {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 900px) {
    .content_left {
        display: none;
    }

    .content_right {
        line-height: 35px;
        width: 100%;
    }

    .content_right h1 {
        display: flex;
    }

    .whoo {
        flex-direction: column;
    }

    .who_right {
        margin: 0 auto;
        width: 100%;
    }

    .who_content {
        width: 90%;
    }

    .h2o {
        left: 25%;
    }
    
    .NH3 {
        left: 80%;
    }
    
    .O2 {
        left: 40%;
    }
    
    .CO2 {
        left: 15%;
    }
    
    .N2 {
        left: 65%;
    }
    
    .CH4 {
        left: 8%;
    }
}

@media (max-width: 768px) {
    .carrousel-item {
        grid-template-columns: repeat(1, 1fr);
    }

    .content_right h1 {
        display: flex;
        transform: scale(0.8);
    }
}


@media (max-width: 570px) {
    .content_right h1 {
        display: flex;
        transform: scale(0.7);
    }
}

@media (max-width: 480px) {
    .carrousel-item {
        grid-template-columns: 1fr;
    }

    .content_right h1 {
        display: flex;
        transform: scale(0.6);
    }
}

