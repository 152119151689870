.info_page {
    width: 100vw;
    height: 100vh;
}

.spécificité {
    margin: 10px;
    /* margin-top: 30px; */
}

.spécificité p {
    line-height: 20px;
}

.head_info {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;
}

.head_info h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
}

.block_info {
    width: 80%;
    max-width: 1490px;
    height: 670px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: row;
    padding: 0 50px;
    gap: 50px;
}

.block_info_left {
    width: 40%;
    height: 100%;
    position: relative;
}

.caroussel_image {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
}

.name {
    position: absolute;
    top: 50px;
    left: -50px;
    background-color: white;
    border: 1px solid rgb(233, 233, 233);
    border-top: 3px solid #ff3f00;
    padding: 10px 30px;
    box-shadow: 2px 2px 23px 1px rgba(0, 0, 0, 0.09);;
    animation: appear_text 2s ease forwards;
}

.name h1 {
    margin: 0;
    font-weight: 500;
    font-size: 25px;
}

.block_info_left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: appear_text 2s ease forwards;
}

.block_info_right {
    width: 60%;
    height: 100%;
    /* background-color: rgb(234, 234, 234); */
    text-align: left;
    padding: 30px;
    position: relative;
}

.block_info_right h1 {
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #ff0000;
    text-underline-offset: 15px;
    animation: appear_text 2s ease forwards;
}

.block_info_right h2 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 25px;
    animation: appear_text 2s ease forwards;
}

.block_info_right p {
    font-size: 20px;
    line-height: 45px;
    animation: appear_text 2s ease forwards;
}

.swip {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.swip h2 {
    margin: 0;
    font-size: 25px;
    font-weight: 500;
}

.swip_left {
    font-size: 40px;
    color: white;
    background-color: #ff0000;
    padding: 10px;
    transition: 0.5s;
}

.swip_left_img {
    font-size: 20px;
    color: white;
    background-color: #ff0000;
    padding: 10px;
    transition: 0.5s;
    cursor: pointer;
}

.swip_left_img:hover {
    transition: 0.5s;
    cursor: pointer;
    background-color: #ba0000;
}

.swip_left:hover {
    transition: 0.5s;
    cursor: pointer;
    background-color: #ba0000;
}

.swip_right {
    font-size: 40px;
    color: white;
    background-color: #ff0000;
    padding: 10px;
    transition: 0.5s;
}

.swip_right_img {
    font-size: 20px;
    color: white;
    background-color: #ff0000;
    padding: 10px;
    transition: 0.5s;
    cursor: pointer;
}

.swip_right_img:hover {
    transition: 0.5s;
    cursor: pointer;
    background-color: #ba0000;
}

.swip_right:hover {
    transition: 0.5s;
    cursor: pointer;
    background-color: #ba0000;
}


@keyframes appear_text {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}