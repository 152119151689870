@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.contact {
    width: 80%;
    max-width: 1900px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
}

.contact__container {
    text-align: start;
}

.contact__container h1 {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.contact__container_both {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    height: 70vh;
}

.contact_left {
    width: 60%;

    margin-left: 30px;
}

.contact_right {
    width: 40%;
    border: 1px solid #d8d8d867;
    border-top: 3px solid #ff3f00;
    padding: 20px;

    box-shadow: 0px 0px 15px 0px #c7c7c767;

    max-width: 500px;
}

.info_icon {
    width: 80%;
    height: 80px;
    display: flex;
}

.icon_contact_left {
    width: 15%;
    height: 100%;
    margin-right: 30px;
}

.icon_contact_left p {
    font-size: 40px;
    padding: 20px;
    color: #220c3c;
}

.icon_contact_right {
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon_contact_right h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 600;
    color: #220c3c;
    margin-bottom: 0;
}

.icon_contact_right P {
    margin-top: 0;
    margin-bottom: 0;
}

.trait {
    width: 100%;
    margin: 0 auto;
    height: 1px;
    background-color: rgb(233, 233, 233);
    text-align: center;
    margin: 20px 0 30px 0;
    justify-content: center;
    align-items: center;
}

.contact_right_padd {
    padding: 10px 20px;
}

@media (max-width: 1200px) {
    .contact__container_both {
        flex-direction: column;
        height: auto;
    }

    .contact_left {
        width: 100%;
        margin-left: 0;
        height: 20vh;
    }

    .contact_right {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 50px;
        max-width: none;
        width: 500px;
    }
}

@media (max-width: 660px) {
    .contact_right {
        width: 100%;
    }

    .icon_contact_right h2 {
        font-size: 20px;
    }

    .icon_contact_left p {
        font-size: 30px;
    }

    .info_icon {
        width: 100%;
    }

    .contact_right_padd {
        padding: 10px 0;
    }
}