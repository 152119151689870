.responsiveNav {
    list-style: none;
    text-decoration: none;
    animation: slideIn 0.5s ease-in-out forwards;

    background-color: #f5f5f5;
    position: absolute;

    top: 100px;
    left: 0;
    width: 100vw;
    z-index: 1000;

    border: #ff3f00 1px solid;

    padding: 50px;
}

.responsiveNav ul {
    list-style: none;
    text-decoration: none;

    padding-left: 0;
    width: 50%;
    margin: 0 auto;
}

.responsiveNav li {
    list-style: none;
    text-decoration: none;

    border-bottom: 1px solid #ff400069;

    padding-top: 20px;
    padding-bottom: 20px;
}

.responsiveNav li:nth-child(1) {
    list-style: none;
    text-decoration: none;

    margin-top: 0px;
}

.responsiveNav li:nth-last-child(1) {
    list-style: none;
    text-decoration: none;

    border-bottom: none;
    margin-top: 0px;
}

.responsiveNav a {
    list-style: none;
    text-decoration: none;

    font-weight: bold;
    font-size: 20px;
}


@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}