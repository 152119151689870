.recrutement {
    scroll-behavior: smooth;
}

.contact-form {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
    border-radius: 8px;
}

.contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: flex;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

input,
textarea,
button {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
}

textarea {
    resize: none;
    height: 100px;
}

button {
    background: #18457b;
    color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s ease;
}

button:hover {
    background: #1d569c;
}

input[type="file"] {
    border: none;
    background: #f4f4f4;
}


.job-post {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    max-width: 900px;
    margin: 50px auto;
    font-family: "Arial", sans-serif;
}

.job-header {
    flex: 1;
    margin-right: 20px;
}

.publish-date {
    font-size: 12px;
    font-weight: 500;
    color: #7b7b7b;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.sector {
    font-size: 16px;
    font-weight: 500;
    color: #7b7b7b;
    margin-bottom: 12px;
}

.job-title {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.apply-button {
    display: inline-block;
    background-color: #18457b;
    color: #fff;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.apply-button:hover {
    color: #fff;
}

.job-details {
    flex: 1;
    border-left: 1px solid #ddd;
    padding-left: 20px;
}

.job-details ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.job-details li {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-bottom: 16px;
    font-weight: 500;
}

.job-details li img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.job-details li:last-child {
    margin-bottom: 0;
}

.icon {
    margin-right: 10px;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
