.login h1 {
    font-size: 30px;
    margin-top: 50px;
    font-family: 'Roboto', sans-serif;
}

.login .contentlogin {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto;
    gap: 20px;
}

.login input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgb(255,60,26);
    color: white;
    cursor: pointer;
}