.App {
  text-align: center;
  scroll-behavior: smooth;
}

::-moz-selection {
	color: #FFF;
	background: #0088CC;
}

::selection {
	color: #FFF;
	background: #0088CC;
}
