.notfound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    flex-direction: column;
    text-align: center;
}

.notfound h1 {
    font-weight: bold;
    font-size: 200px;
    color: #e6e6e6;
}

.notfound h2 {
    font-size: 50px;
    color: #000000;
    margin-top: 80px;
    font-weight: 500;
}

.notfound a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.notfound a:hover {
    transition: all 0.3s ease;
    color: white;
    background-color: #ff3f00;;
}