.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    max-width: 600px;
    border-radius: 20px !important;
}

.modal-content h2 {
    margin-bottom: 0px;
}

.modal-close {
    margin-top: 10px;
    padding: 8px 16px;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.modal-close:hover {
    background: darkred;
}

.btn-group {
    background-color: #18457B;
    width: 100%;
    padding: 10px 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    font-size: bold;
    cursor: pointer;
    transition: 0.3s;
}

.btn-group:hover {
    background-color: #0D2C4C;
}