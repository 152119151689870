.offre-content {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 20px auto;
}

.input-group-check {
    display: flex;
    align-items: center;
}

.input-group-check input {
    width: 20px;
    margin-bottom: 2px;
}

.input-group input {
    border-radius: 5px !important;
}

.input-group textarea {
    border-radius: 5px !important;
}

.input-group {
    margin: 10px 0;
}

.container-form {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
}

.offre-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 20px;
    width: 90%;
    margin: 20px auto;
}

.offre-item {
    display: flex;
    justify-content: space-between;
}

.job-post.gest {
    min-width: 600px;
    width: 100%;
}

.activeOrNot {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
}

.activeOrNot input {
    width: 20px;
}

.btn-edit {
    margin-bottom: 20px;
}