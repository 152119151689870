/* #220c3c */

.espace_client h1 {
    color: #220c3c;
    font-size: 45px;
    font-weight: 700;
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 30px;
}

.espace_client h4 {
    color: #ff3f00;
    font-size: 20px;
    font-weight: 400;
    width: 80%;
    margin: 0 auto;
    margin-top: 0px;
}

.espace_client h2 {
    color: #220c3c;
    font-size: 25px;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.title_doc {
    color: #220c3c;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.a_doc button {
    color: white;
    background-color: #ff3f00;
    font-size: 10px;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    border: none;
}

.a_doc {
    border-bottom: 1px solid #d8d8d867;
    margin-top: 20px;
    padding-bottom: 20px;
}

.a_doc a {
    color: white;
    background-color: #ff4000de;
    font-size: 10px;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 10px;
}

.espace_client .liste_document {
    display: flex;
    width: 60%;
    margin: 0 auto;
    list-style: none;
    margin-bottom: 50px;
    margin-top: 50px;
}

.espace_client .liste_document .item_doc {
    margin: 0 auto;
    margin-top: 30px;
    padding: 10px;
    border-radius: 5px;

    border: 1px solid #d8d8d867;
    border-top: 3px solid #ff3f00;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px #c7c7c767;

    width: 400px;
}

.liste_doc {
    overflow-y: scroll;
    max-height: 200px;
    background-color: rgb(247, 247, 247);
    padding: 10px;
    border-radius: 10px;
}

.not_logged_back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.not_logged {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not_logged h2 {
    color: #220c3c;
    font-size: 25px;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.not_logged div {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.not_logged div input {
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d8d8d867;
    margin-bottom: 10px;
}

.not_logged div button {
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d8d8d867;
    background-color: #ff3f00;
    color: white;
    font-weight: 600;
}