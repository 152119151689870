.headerDash {
    display: flex;
    justify-content: space-between;
    background-color: #101112;
    height: 7vh;
}

.headerDash h1 {
    color: #bbc0ca;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 10px 20px;

    display: flex;
    align-items: center;
}

.headerDash .logout {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #bbc0ca;
    transition: 0.3s;
}

.headerDash .logout:hover {
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.pageDash .dashboard {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 93vh;
}

.pageDash .dashboard .tableDb {
    width: 30%;
    height: 100%;
    min-width: 300px;
    border-right: 1px solid #e8e8e8;
}

.pageDash .dashboard .tableDb .catElements {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    gap: 10px;
}

.pageDash .dashboard .tableDb .catElement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.pageDash .dashboard .tableDb .catElement:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    transition: 0.3s;
}

.pageDash .dashboard .tableDb .catElement.selected {
    background-color: #2276fc;
    border-radius: 5px;
    color: white;
}

.pageDash .dashboard .tableDb .catElement.selected h1 {
    color: white;
}

.pageDash .dashboard .tableDb .catElement h1 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 20px;
}

.pageDash .dashboard .tableDb .nameCat {
    color: #bbc0ca;
    height: 7vh;
    background-color: white;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
}

.pageDash .dashboard .tableDb .nameCat h1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
}

.pageDash .dashboard .itemsTable {
    width: 30%;
    height: 100%;
    min-width: 300px;
    border-right: 1px solid #e8e8e8;

}

.pageDash .dashboard .itemsTable .offset {
    overflow-y: auto;
    height: 87%;
    scrollbar-width: thin;
}

.pageDash .dashboard .itemsTable .clientList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    gap: 10px;
}

.pageDash .dashboard .itemsTable .clientList .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}

.pageDash .dashboard .itemsTable .clientList .item.current {
    background-color: #2276fcad;
    color: white !important;
}

.pageDash .dashboard .itemsTable .clientList .item.current h1 {
    color: white !important;
}

.pageDash .dashboard .itemsTable .clientList .item.current:hover {
    background-color: #2276fc;
}

.pageDash .dashboard .itemsTable .clientList .item:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    transition: 0.3s;
}

.pageDash .dashboard .itemsTable .clientList .item h1 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

.pageDash .dashboard .itemsTable .nameCat {
    color: #bbc0ca;
    height: 12vh;
    background-color: white;
    display: flex;
    justify-content: start;
    flex-direction: column;
    border-bottom: 1px solid #e8e8e8;
}

.pageDash .dashboard .itemsTable .nameCat h1 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px 0 20px;
}

.pageDash .dashboard .itemsTable .nameCat .headerCat {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pageDash .dashboard .itemsTable .nameCat .headerCat .edit {
    display: flex;
    color: #000000;
    transition: 0.3s;
    padding: 10px;
    gap: 10px;
}

.pageDash .dashboard .itemsTable .nameCat .headerCat .edit .alpha {
    transition: 0.3s;
}

.pageDash .dashboard .itemsTable .nameCat .headerCat .edit .but {
    transition: 0.3s;
}

.pageDash .dashboard .itemsTable .nameCat .headerCat .edit .alpha:hover {
    cursor: pointer;
    color: #7f7f81;
}

.pageDash .dashboard .itemsTable .nameCat .headerCat .edit .but:hover {
    cursor: pointer;
    color: #7f7f81;
}

.pageDash .dashboard .itemsTable .nameCat input {
    width: 90%;
    height: 30px;
    margin: 10px 20px;
    padding: 10px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
}

.pageDash .dashboard .item {
    width: 100%;
    height: 100%;
}

.pageDash .dashboard .item .nameCat {
    color: #bbc0ca;
    height: 12vh;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
}

.pageDash .dashboard .item .nameCat h1 {
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
}

.pageDash .dashboard .item .nameCat .close {
    padding: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.pageDash .dashboard .item .nameCat .close:hover {
    color: #7f7f81;
}

.pageDash .dashboard .item .publishContent {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 20px;

    gap: 20px;
}

.pageDash .dashboard .item .publishContent .publish {
    padding: 10px 20px; 
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    right: 0;
    background-color: #e8e8e8;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.pageDash .dashboard .item .publishContent .publish:hover {
    background-color: #bebebe;
}

.pageDash .dashboard .item .publishContent .publish h1 {
    margin: 0;
    margin-left: 20px;
    font-size: 25px;
    font-weight: 600;
}

.itemContent {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px 50px;
}

.nameItem.create {
    margin-bottom: -30px;
}

.itemContent .nameItem, .itemContent .email, .itemContent .company, .itemContent .password {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.itemContent .nameItem input, .itemContent .email input, .itemContent .company input, .itemContent .password input, .typeDoc input, .clientDoc input {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
}

.itemContent .clientDoc select, .document input, .typeDoc select {
    width: 100%;
    height: 55px;
    padding: 10px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
}

.document h2, .typeDoc h2, .clientDoc h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    text-align: start;
}

.document button {
    padding: 10px 20px;
    background-color: #2276fc;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    width: 100%;
}

.document button:hover {
    background-color: #0f5f9b;
}

.itemContent .clientDoc select option {
    padding: 10px;
}

.itemContent .nameItem h2, .itemContent .email h2, .itemContent .company h2, .itemContent .password h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.passwordWithGen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.passwordWithGen button {
    padding: 10px 20px;
    background-color: #2276fc;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    width: 30%;
}

.left-dash-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 7vh;
    background-color: #101112;
    gap: 20px;
}

.left-dash-header a {
    color: #bbc0ca;
    transition: 0.3s;
    font-weight: bold;
    background-color: #2b2d30;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.3s;
}

.left-dash-header a:hover {
    color: white;
    background-color: #2276fc;
    text-decoration: none;
}