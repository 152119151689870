@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*@BASIC-BUTTON-PE*/
.basic-button {
    font-family: 'Poppins', sans-serif;
}

.basic-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

/*@AVATAR-PE*/
.circle p {
    font-family: 'Poppins', sans-serif;
}

/*@ACCORDION-PE*/
.accordion {
    color: white;
    font-family: 'Poppins', sans-serif;
}

.accordion-item {
    margin-bottom: 10px;
}

.accordion-content {
    font-family: 'Poppins', sans-serif;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0, 0.9, 0, 0.9);
}

.accordion-content.active {
    opacity: 1;
    height: auto;
    max-height: 200px;
    transition: all 0.4s cubic-bezier(0.5, 0, 0.5, 0);
}

@media (max-width: 768px) {
    .accordion h2 {
        width: 90% !important;
    }
}

/*@Autocomplete-PE*/
.autoc-container {
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: 100%;
}

.autoc-container input:focus-visible {
    outline: none;
}

.suggestions {
    position: absolute;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s;
    top: 110%;
    z-index: 100;
}

.suggestions.active {
    opacity: 1;
    height: auto;
    max-height: 400px;
    border-radius: 15px;
}

.scroll-items {
    overflow-y: scroll;
    height: auto;
    max-height: 300px;
    border-radius: 15px;
}

.scroll-items::-webkit-scrollbar {
    width: 5px;
}

.scroll-items::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 15px;
}

.scroll-items li {
    cursor: pointer;
    border-radius: 10px;
    transition: 0.3s;
}

.suggestion {
    padding: 15px 10px;
}

.input-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
    transition: all 0.3s ease;
    pointer-events: none;
}

.input-label.active {
    transform: translate(0, -125%);
    font-size: 12px !important;
    color: gray;
}

.show-open {
    position: absolute;
    top: 45%;
    transform: translateY(-45%);
    right: 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    cursor: pointer;
}

.arrows {
    transform: rotate(270deg);
    transition: 0.3s;
}

.arrows.active {
    transform: rotate(450deg);
}

.remove {
    position: absolute;
    top: 46%;
    right: 40px;
    transform: translateY(-50%);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    cursor: pointer;
}

.removeInput {
    position: absolute;
    top: 48%;
    right: 15px;
    transform: translateY(-50%);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    cursor: pointer;
}

input {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

input::placeholder {
    color: rgb(120, 120, 120);
}


.doc-component {
    display: flex;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    gap: 30px;
}

.sidebar {
    width: 15%;
    padding: 20px 0;
    text-align: left;
}

.sidebar h2 {
    font-weight: 400;
    font-size: 20px;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.sidebar h2:hover {
    cursor: pointer;
    opacity: 0.8;
}

.sidebar ul {
    margin-bottom: 20px
}

.sidebar .subItemTitle {
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    margin-left: 20px;
    margin-bottom: 10px;
}

.sidebar .subItemTitle div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.sidebar .subItemTitle div:hover {
    opacity: 0.8;
}

.content {
    width: 60%;
    padding: 20px;
    overflow-y: auto; /* Seul le contenu est défilable */
    text-align: left;

    height: 80vh;
}

.content::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f534;
    border-radius: 10px;
}

.content::-webkit-scrollbar-thumb {
    background-color: #bababa86;
    border-radius: 4px;
}

.content::-webkit-scrollbar-thumb:hover {
    background-color: #dcdcdc;
}

.content::-webkit-scrollbar-button {
    display: none;
}

.content h2 {
    font-size: 30px;
}

.toc {
    padding: 20px;
    overflow-y: auto; /* Seul le contenu est défilable */
    text-align: left;
}

.toc-item {
    cursor: pointer;
    padding: 2px;
    font-size: 15px;
}

.toc-item.active {
    font-weight: bold;
    color: #7e8892;
}

.itemTitle {
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.doc-nav {    
    backdrop-filter: blur(50px);

    font-family: 'Poppins', sans-serif;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.doc-nav .row {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 40px;
}

.doc-nav .row h1 {
    font-size: 30px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s;
}

.doc-nav .row h1:hover {
    opacity: 0.8;
}

.doc-nav .row h1 a {
    text-decoration: none;
}

.doc-nav .row h1 span {
    font-weight: 400;
    font-size: 15px;
    margin-left: 10px;
}

.doc-nav .row div a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.3s;
}

.doc-nav .row div a.active {
    color: #006fee;
    font-weight: 400;
}

.doc-nav .row div a:hover {
    opacity: 0.6;
}

.doc-nav .row .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.searchNav {
    display: flex;
    align-items: center;
    gap: 10px;

    border-radius: 15px;
    padding: 10px 50px 10px 20px;
}

.searchNav h3 {
    font-size: 15px;
    font-weight: 400;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}

.modalNav {
    position: absolute;
    animation: fadeOut 0.3s forwards;
    height: 80vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.modalNav.active {
    position: absolute;
    animation: fadeIn 0.3s;
    height: 80vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    overflow-y: hidden;
}

.backModal {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
}

.modalNav-content {
    border-radius: 15px;
    position: absolute;
    z-index: 1000 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 80%;
    max-width: 800px;
    max-height: 500px;
    overflow-y: none;
    padding: 0 0 20px 0;
}

.searchContent {
    padding: 20px;
    display: flex;
    gap: 20px;
}

.searchContent .logoSearch {
    position: absolute;
}

.searchContent input {
    border: none;
    width: 100%;
    padding: 7px;
    padding-left: 50px;
    font-size: 15px;
}

.searchContent input:focus-visible {
    outline: none;
}
